// @ts-ignore
import ODate from '@financial-times/o-date'
import * as pageKitLayout from '@financial-times/dotcom-ui-layout'
import * as pageKitFlags from '@financial-times/dotcom-ui-flags'
import * as pageKitAppContext from '@financial-times/dotcom-ui-app-context'
import * as nTracking from '@financial-times/n-tracking'
import { nMessagingClient } from 'n-messaging-client/main-client'
import * as nSyndication from '@financial-times/n-syndication'

import adverts from './adverts'
import marketsData from './marketsData'
import { makeUiCcpaCompliant } from './privacy/make-ccpa-compliant'
import chartbeat from './chartbeat'
import * as nExponea from '@financial-times/n-exponea'
import workspaceNavInit from '@financial-times/workspace-menu'

import { init as interactiveEmbedInit } from '@financial-times/dotcom-interactive-embed/src/parent.js'

import { initTracking as initCommunityHomePageSliceTracking } from '@financial-times/community-home-page-slice'
import { isPGAdsEnabled } from '../lib/toggles'
import Clip from '@financial-times/cp-content-pipeline-ui/lib/components/Clip/client/index'

export default function loader() {
	// @ts-ignore
	const flags = pageKitFlags.init()
	// @ts-ignore
	const appContextClient = pageKitAppContext.init()
	const appContext = appContextClient.getAll()
	const headerOptions = flags.get('enhancedSearchTest')
		? { enhancedSearchUrl: 'https://enhanced-search.ft.com/?q=', searchState: 'open' }
		: {}

	ODate.init()
	// @ts-ignore
	pageKitLayout.init({ headerOptions })

	if (flags.get('syndication')) {
		nSyndication.init(flags)
	}
	const isClipsEnabled = flags.get('homepageClips') && flags.get('homepageTestsHoldoutGroup') !== 'variant'
	if (isClipsEnabled) {
		try {
			Clip.init(null, { intersectionObserverThreshold: 1, fadeOutDelay: 500 })

			const handleClipLoadingFailure = (event: CustomEvent) => {
				try {
					const clipInstance = event?.detail?.clipInstance
					if (clipInstance.opts.autoplay) {
						const clipContainer = clipInstance?.containerEl as HTMLElement
						const clipId = clipContainer?.getAttribute('data-cp-clip-id')
						console.error('Clip component failed to load #data-cp-clip-id', clipId)
						const target = event?.target as HTMLElement
						target?.classList?.add('hidden')
						clipInstance?.destroy()
						document.getElementById(clipId)?.classList?.remove('hidden')
					}
				} catch (error) {
					console.error('Error handling clip loading failure', error)
				}
			}

			window.addEventListener('cpContentPipeline.clipComponent.loadingFailure', handleClipLoadingFailure)
		} catch (error) {
			console.error('Clip component failed to initialise', error)
		}
	}

	const isGraphicsEnabled =
		flags.get('homepageInteractivesEmbeds') && flags.get('homepageTestsHoldoutGroup') !== 'variant'
	if (isGraphicsEnabled) {
		try {
			interactiveEmbedInit({ noFlourish: !flags.get('useFlourish') })
			const handleFlourishLoadingFailure = (event) => {
				// in order to hide the flourish embeds, we need first need to find all flourish placeholders
				// then we need to add a class `hidden` to hide them
				document.querySelectorAll('.interactive-graphic-slice--flourish').forEach((el) => {
					el.classList.add('hidden')
				})
			}
			// Add event listener for when a flourish embed fails to load
			window.addEventListener('dotcomInteractiveEmbed.flourish.loadingFailure', handleFlourishLoadingFailure)
		} catch (error) {
			console.error(error)
		}
	}

	// Tracking initializations.
	if (flags.get('oTracking')) {
		const oTracking = nTracking.init({ appContext })
		oTracking.view.init({
			selector: '.slice',
			category: 'page',
			action: 'scrolldepth',
		})

		oTracking.view.init({
			selector: '.list__item--pointcut',
			category: 'component',
			action: 'view',
			getContextData: (el) => {
				return {
					componentContentId: el.getAttribute('data-id'),
					component: {
						id: el.getAttribute('data-id'),
						name: el.getAttribute('data-name'),
						type: el.getAttribute('data-type'),
						subtype: el.getAttribute('data-subtype'),
					},
				}
			},
		})

		if (flags.get('realUserMonitoringForPerformance')) {
			try {
				const sampleRate = flags.get('chartbeat') ? 50 : undefined
				nTracking.trackers.realUserMonitoringForPerformance({ sampleRate })
				nTracking.trackers.realUserMonitoringWithAttribution()
			} catch (error) {
				console.error(error)
			}
		}

		if (flags.get('frontPageCommunitySection')) {
			initCommunityHomePageSliceTracking(oTracking)
		}
	}

	if (nExponea.canShowExponea(flags)) {
		try {
			nExponea.init()
		} catch (error) {
			console.error('Error initialising Exponea SDK', error) //eslint-disable-line no-console
		}
	}

	if (!flags.get('adsDisableInternalCMP')) {
		nMessagingClient.init()
	}

	const isLegacyAdsEnabled = !isPGAdsEnabled(flags)
	if (flags.get('ads') && isLegacyAdsEnabled) {
		adverts.init(flags, appContextClient).finally(() => {
			if (flags.get('nativeAds')) {
				adverts.displayNativeAds()
			}
		})
		// Enable permutive when user provides consent in cookie banner
		// `displayAds` also needs to be initialised again to ensure user info is updated
		document.addEventListener('oCookieMessage.act', () => {
			// A small timeout is set as `oCookieMessage.act` is fired BEFORE consent request is made and cookie is updated
			setTimeout(async () => {
				await adverts.updateConsentInfo({
					updatePrivacyLegislation: false,
					updateGpcValue: false,
					updateConsentCookie: true,
				})
				await adverts.init(flags, appContextClient)
			}, 1000)
		})
	}

	if (flags.get('homePageHeaderMarketsData')) {
		marketsData.init(appContext)
	}

	if (flags.get('chartbeat')) {
		chartbeat()
	}

	makeUiCcpaCompliant()

	/**
	 * Initialise the client side Workspace menu with tooltip message for main navigation (B2B users only).
	 * Team: LifeCycle - Enterprise
	 */
	workspaceNavInit({
		app: appContext.appName || 'home-page',
		enabled: !flags.get('myFtWorkspaceTest') && flags.get('enterpriseWorkspaceNav'),
		navTarget: '[data-component="nav-list--right"]',
		drawerTarget: '[data-component="drawer-menu--primary__drawer-menu-list"]',
		drawerDividerTarget: '[data-component="drawer-menu-item--divide"]',
	}).catch((error) => {
		// eslint-disable-next-line no-console
		console.error('Workspace navigation failed to initialise, please inform LifeCycle team in Enterprise', error)
	})
}
